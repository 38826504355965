import React from 'react';
import Button from '../../button';
import Icon from '../../icon';
import ThemeEdit from './form'

// fastest method for deep clone of pure json objects.
// (slice() is shallow clone.)
const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

class AddItem extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey

    this.handleAdd = this.handleAdd.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleAdd(e) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'add', data: true },
      { key: this.key, signal: 'update', data: null },
      { key: this.key, signal: 'delete', data: null }
    ])
  }

  handleCancel(e) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'add', data: null }
    ])
  }

  shouldRender() {
    const signals = this.props.signals
    var render = false

    if (signals.add) {
      render = true
    }

    return render
  }

  render() {
    const props = this.props

    return (this.shouldRender() ?
      <li className='list-item library selected'>
        <div className='image-frame wide center'>
          <Icon icon='site'/>
        </div>
        <ThemeEdit {...props}
          submitLabel='Create'
          handleCancel={this.handleCancel}
        />

      </li>
      :
      <Button
        icon='add'
        label='Add Theme'
        handleClick={(e) => { this.handleAdd(e) }}
        buttonClass='btn'
      />
    )
  }
}

export default AddItem;
