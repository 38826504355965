import React from 'react';
import Button from '../../button';
import ListItem from './list_item';
import UpdateItem from './update_item';
import DeleteItem from './delete_item';
import AddItem from './add_item';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey
  }

  isAdmin() {
    const user = this.props.user

    return this.props.user &&
      this.props.user.site == 'admin' &&
      this.props.user.roles.includes('admin')
  }

  render() {
    const signals = this.props.namedSignals[this.key]
    const data = signals && signals.list
    const isAdmin = this.isAdmin()
    const loading = <div>Loading...</div>
    const list = (data &&
      <ul className='list'>
        {isAdmin &&
          <AddItem
            signalsKey={this.key}
            signals={signals}
            setNamedSignals={this.props.setNamedSignals}
          />
        }
        {data.map((o) =>
          <div key={o.id}>
            <ListItem
              item={o}
              dns_zone={this.props.user.dns_zone}
              signalsKey={this.key}
              signals={signals}
              setNamedSignals={this.props.setNamedSignals}
              isAdmin={isAdmin}
              user={this.props.user}
              params={this.props.params}
            />
            <UpdateItem
              item={o}
              dns_zone={this.props.user.dns_zone}
              signalsKey={this.key}
              signals={signals}
              setNamedSignals={this.props.setNamedSignals}
            />
            <DeleteItem
              item={o}
              dns_zone={this.props.user.dns_zone}
              signalsKey={this.key}
              signals={signals}
              setNamedSignals={this.props.setNamedSignals}
            />
          </div>
        )}
      </ul>
    )
    return (
      <React.Fragment>
        {data && list}
        {!data && loading}
      </React.Fragment>
    )
  }
}

export default List;
