import React from 'react';
import List from './theme_list/list'
import Fetch from './list/fetch'
import UpdateList from './list/update_list'

class ThemeListBlock extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.block.instance.attributes_hash.url
  }

  render() {
    const props = this.props

    return (
      <React.Fragment>
        <List {...props} signalsKey={this.key} />
        <Fetch {...props} signalsKey={this.key} />
        <UpdateList {...props} signalsKey={this.key} />
      </React.Fragment>
    )
  }
}

export default ThemeListBlock;
