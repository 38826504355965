import React from 'react';
import Button from '../../button';
import { Line, Circle } from 'rc-progress';

const AjaxAPI = window.AjaxAPI;

class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey

    this.state = {progress: null}

    this.handlePreview = this.handlePreview.bind(this);
    this.handleInstall = this.handleInstall.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.siteUrl = this.siteUrl.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
  }

  handlePreview(e, item) {
    const url = this.props.user.env == 'development' ?
    'http://'+item.name+'.testmakki.com:3000' :
    'https://'+item.name+'.temakki.com'

    window.open(url,'_blank');
  }

  siteUrl(siteName) {
    const url = (this.props.user.env == 'development') ?
    'http://'+siteName+'.testmakki.com:3000' :
    'https://'+siteName+'.temakki.com'

    return url
  }

  updateProgress(value) {
    this.setState({progress: value});
  }

  handleInstall(e, item) {
    const siteId = this.props.params && this.props.params.site_id
    const siteUrl = this.siteUrl

    const _this = this
    _this.updateProgress(20)

    const post_data = {
      attributes: { theme: item.name }
    }
    ;(new AjaxAPI).post(post_data, '/attributes/site/'+siteId, function(data){
      _this.updateProgress(40)

      ;(new AjaxAPI).post({}, '/sites/install_template/'+siteId, function(data){
        _this.updateProgress(80)
        if (data.responseJSON) {
          //console.log(data)
        } else {
          ;(new AjaxAPI).post({}, '/sites/generate_preview/'+siteId, function(data){
            _this.updateProgress(100)
            if (data.responseJSON) {
              //console.log(data)
            } else {
              const siteName = data.data.name
              window.location.href = siteUrl(siteName)
            }
          })
        }
      })

    });
  }

  handleUpdate(e, item) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'update', data: item }
    ])
  }

  handleDelete(e, item) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'delete', data: item }
    ])
  }

  shouldRender() {
    const signals = this.props.signals
    const itemId = this.props.item.id
    var render = true

    const signalObjects = [
      signals.update,
      signals.updateAttributes,
      signals.delete
    ]

    signalObjects.forEach(function(obj, i) {
      if (obj && (obj.id == itemId)) {
        render = false
      }
    })
    return render
  }

  render() {
    const props = this.props
    const item = props.item
    const attributes = item.attributes_hash || {}
    const previewUrl = item.preview
    const temakkiUrl = item.name + '.' + this.props.dns_zone

    const progress = (
      <div className='main'>
        <div className='name'>
          <Line
            percent={this.state.progress}
            label={`${this.state.progress}%`}
            strokeWidth='2'
            strokeColor='#888'
          />
        </div>
        <div className='info'>
          Wait for it...
        </div>
      </div>
    )

    const list_item = (
      <React.Fragment>
        <div className='main'>
          <div className='name'>
            {item.title}
          </div>
          <div className='info'>
            {item.description}
          </div>
        </div>
        {this.props.isAdmin ?
          <React.Fragment>
            <Button
              label='Edit'
              handleClick={(e) => { this.handleUpdate(e,item) }}
              buttonClass='btn'
            />
            <Button
              label='Delete'
              handleClick={(e) => { this.handleDelete(e,item) }}
              buttonClass='btn'
            />
          </React.Fragment>
        :
          <React.Fragment>
            <Button
              label='Preview'
              handleClick={(e) => { this.handlePreview(e,item) }}
              buttonClass='btn'
            />
            <Button
              label='Install'
              handleClick={(e) => { this.handleInstall(e,item) }}
              buttonClass='btn'
            />
          </React.Fragment>
        }
      </React.Fragment>
    )

    return (this.shouldRender() ?
      <li className='list-item library'>
        <div className='image-frame wide center'
          style={{ backgroundImage : `url(${previewUrl})` }} >
        </div>
        {this.state.progress ? progress : list_item}
      </li>
      : null
    )
  }
}

export default ListItem;
