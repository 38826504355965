import React from 'react';
import Button from '../../button';

const AjaxAPI = window.AjaxAPI;
const InputWrapper = window.InputWrapper
const withFormik= window.withFormik;
const Form = window.Form;
const Yup = window.Yup;

const ThemeEditForm = (props) => (
  <Form className='main row'>
    <div className='col-9'>
      <InputWrapper {...props}
        name='title'
        placeholder="Theme Title"
        label='Title'
        className='row'
        labelClass='col-3 col-form-label'
        fieldClass='col-9'
      />

      <InputWrapper {...props}
        name='name'
        placeholder="valid-host-name"
        label='Subdomain'
        className='row'
        labelClass='col-3 col-form-label'
        fieldClass='col-9'
      />

      <InputWrapper {...props}
        name='description'
        type='textarea'
        label='Description'
        className='row'
        labelClass='col-3 col-form-label'
        fieldClass='col-9'
      />
    </div>
    <div className='col-3'>
      <div className='bottom-buttons'>
        <Button
          label={props.submitLabel}
          type='submit'
          disabled={props.isSubmitting}
          buttonClass='btn'
        />
        <Button
          label='Cancel'
          handleClick={props.handleCancel}
          buttonClass='btn'
        />
      </div>
    </div>
  </Form>
);

const ThemeEdit = withFormik({
  mapPropsToValues(props) {
    return {
      title: (props.data && props.data.title) || '',
      name: (props.data && props.data.name) || '',
      description: (props.data && props.data.description) || ''
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Subdomain is required')
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    const url = props.signalsKey
    const key = url
    const item = props.signals.update
    const setNamedSignals = props.setNamedSignals
    if (item && item.id) {
      (new AjaxAPI).put(values, url+'/'+item.id, function(data){
        if (data.responseJSON && data.responseJSON.errors) {
          setErrors(data.responseJSON.errors)
        } else {
          setNamedSignals([
            { key: key, signal: 'update', data: null },
            { key: key, signal: 'updated', data: data.data },
          ])
        }
      })
    } else {
      (new AjaxAPI).post(values, url, function(data){
        if (data.responseJSON && data.responseJSON.errors) {
          setErrors(data.responseJSON.errors)
        } else {
          setNamedSignals([
            { key: key, signal: 'add', data: null },
            { key: key, signal: 'added', data: data.data }
          ])
        }
      })
    }
    setSubmitting(false)
  }
})(ThemeEditForm)

export default ThemeEdit
