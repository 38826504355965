import React from 'react';

// fastest method for deep clone of pure json objects.
// (slice() is shallow clone.)
const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

class UpdateList extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey
    this.setNamedSignals = this.props.setNamedSignals
  }

  componentDidUpdate(prevProps) {
    const signals = this.props.namedSignals[this.key]

    if (signals && (signals.added || signals.updated || signals.deleted)) {
      var data = clone(signals)

      if (data.added) {
        this.addItem(data.added, data.list)
      }
      if (data.updated) {
        this.updateItem(data.updated, data.list)
      }
      if (data.deleted) {
        this.deleteItem(data.deleted, data.list)
      }
      this.setNamedSignals([
        { key: this.key, signal: 'list', data: data.list },
        { key: this.key, signal: 'added', data: null },
        { key: this.key, signal: 'updated', data: null },
        { key: this.key, signal: 'deleted', data: null },
      ])
      if (data.added) {
      //  this.setNamedSignals([
      //    { key: this.key, signal: 'updateAttributes', data: data.added }
      //  ])
      }
    }
  }

  addItem(item, list) {
    return list.unshift(item)
  }

  updateItem(item, list) {
    var index = null;

    list.forEach(function(o, i) {
      if (o.id == item.id) { index = i; }
    });
    if (index  != null) { list[index] = item; }

    return list
  }

  deleteItem(item, list) {
    var index = null;

    list.forEach(function(o, i) {
      if (o.id == item.id) { index = i; }
    });
    if (index  != null) { list.splice(index, 1); }

    return list
  }

  render() {
    return null
  }
}

export default UpdateList
