import React from 'react';
import Button from '../../button';
import Dropzone from 'react-dropzone'
import ThemeEdit from './form'

// fastest method for deep clone of pure json objects.
// (slice() is shallow clone.)
const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

class UpdateItem extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey

    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleDelete(e, item) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'update', data: null },
      { key: this.key, signal: 'delete', data: item }
    ])
  }

  handleCancel(e, item) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'update', data: null }
    ])
  }

  shouldRender() {
    const signals = this.props.signals
    const itemId = this.props.item.id
    var render = false

    if (signals.update && (signals.update.id == itemId)) {
      render = true
    }

    return render
  }

  render() {
    const props = this.props
    const item = props.item
    const previewUrl = item.preview

    return (this.shouldRender() ?
      <li className='list-item library selected'>
        <div className='image-frame wide center'
          style={{ backgroundImage : `url(${previewUrl})` }} >
        </div>
        <ThemeEdit {...props}
          data={item}
          submitLabel='Update'
          handleCancel={this.handleCancel}
        />
      </li>
      : null
    )
  }
}

export default UpdateItem;
