import React from 'react';
import Button from '../../button';

const AjaxAPI = window.AjaxAPI;

class DeleteItem extends React.Component {
  constructor(props) {
    super(props);

    this.key = this.props.signalsKey

    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleDelete(e, item) {
    const url = this.props.signalsKey + '/' + item.id;
    const setNamedSignals = this.props.setNamedSignals;
    const key = this.key;

    (new AjaxAPI).delete(url, function(data){
      setNamedSignals([
        { key: key, signal: 'delete', data: null },
        { key: key, signal: 'deleted', data: item }
      ])
    });
  }

  handleCancel(e, item) {
    this.props.setNamedSignals([
      { key: this.key, signal: 'delete', data: null }
    ])
  }

  shouldRender() {
    const signals = this.props.signals
    const itemId = this.props.item.id
    var render = false

    if (signals.delete && (signals.delete.id == itemId)) {
      render = true
    }

    return render
  }

  render() {
    const props = this.props
    const item = props.item
    const previewUrl = item.preview
    const temakkiUrl = item.name + '.' + this.props.dns_zone

    return (this.shouldRender() ?
      <li className='list-item library selected'>
        <div className='image-frame wide center'
          style={{ backgroundImage : `url(${previewUrl})` }} >
        </div>
        <div className='main'>
          <div className='name'>
            {item.title}
          </div>
          <div className='info'>
            {item.name}
          </div>
        </div>
        <div className='main message'>
          Caution. Delete is permanent.
        </div>
        <Button
          label='Delete'
          handleClick={(e) => { this.handleDelete(e,item) }}
          buttonClass='btn delete'
        />
        <Button
          label='Cancel'
          handleClick={(e) => { this.handleCancel(e,item) }}
          buttonClass='btn'
        />
      </li>
      : null
    )
  }
}

export default DeleteItem;
